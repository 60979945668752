import * as R from 'ramda'
import {a, animated, useSpring} from 'react-spring'
import loadable from '@loadable/component'
import React, {useMemo, useRef, useState} from 'react'

import {carouselPropTypes} from 'helpers/propTypes'
import {indexChecker} from 'helpers/utils'
import classNames from 'classnames'
import Description from 'components/UI/Description'
import RoundButtonSlider from 'components/UI/RoundButtonSlider'
import Section from 'components/UI/Section'
import Title from 'components/UI/Title'
import useIsMobile from 'hooks/useIsMobile'
import useMeasure from 'react-use-measure'

import MainCarouselDecorations from './MainCarouselDecorations'
import MainCarouselVideo from './MainCarouselVideo'
import useStyles from './styles'

const MainCarousel = ({carousel, socialMediaLinks, webSiteName}) => {
  const RoundButton = loadable(() => import('components/UI/RoundButton'))
  const mapIndexed = R.addIndex(R.map)

  const Social = loadable(() => import('components/UI/Social'))
  const classes = useStyles()
  const isMobile = useIsMobile()

  const [ref, {height}] = useMeasure()

  const [index, setIndex] = useState(0)
  const [isCarouselReady, setIsCarouselReady] = useState(false)
  const [isCarouselUp, setIsCarouselUp] = useState(false)
  const [topImage, setTopImage] = useState(undefined)
  const [bottomImage, setBottomImage] = useState(0)
  const [topImageZIndex, setTopImageZIndex] = useState(undefined)
  const [bottomImageZIndex, setBottomImageZIndex] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [isZoomOn, setIsZoomOn] = useState(false)
  const [isMoveOnTop, setIsMoveOnTop] = useState(false)
  const [isMoveOnBottom, setIsMoveOnBottom] = useState(false)
  const [isAutoPlay, setIsAutoPlay] = useState(true)
  const [ctaColor, setCtaColor] = useState('primary')

  const colors = [{color: 'primary'}, {color: 'secondary'}, {color: 'tertiary'}]

  // eslint-disable-next-line fp/no-loops
  while (colors.length < carousel.length) {
    // eslint-disable-next-line fp/no-mutating-methods
    colors.push(...colors)
  }

  useMemo(() => {
    setCtaColor(colors[index].color)
  }, [index])

  useMemo(() => {
    if (R.length(carousel) > 1) {
      setTimeout(() => {
        setIsLoading(false)
        setIsCarouselUp(true)
      }, [300])
    } else {
      setIsLoading(false)
    }
  }, [])

  const refTop = useRef(null)

  const refBottom = useRef(null)

  const switchSlide = mutation => {
    if (isCarouselReady) {
      setIndex(indexChecker(index, mutation, carousel))
      if (isCarouselUp) {
        setTopImage(indexChecker(bottomImage, mutation, carousel))
        setTopImageZIndex(1)
        setBottomImageZIndex(0)
      }
      if (!isCarouselUp) {
        setBottomImage(indexChecker(topImage, mutation, carousel))
        setBottomImageZIndex(1)
        setTopImageZIndex(0)
      }
    }
  }

  const isExternalLink = link => R.includes('https://', link)

  const onLiftStart = () => {
    setIsZoomOn(true)
  }

  const onLiftRest = () => {
    if (!isCarouselReady) {
      setTopImage(1)
      setIsCarouselReady(true)
    } else {
      setIsMoveOnTop(false)
      setIsMoveOnBottom(false)
    }
    setIsZoomOn(false)
  }

  const onButtonClick = (mutation, isClick) => {
    setIsAutoPlay(!isClick)
    switchSlide(mutation)
    setIsLoading(true)
    setTimeout(() => {
      if (isCarouselUp) {
        setIsMoveOnBottom(true)
      }
      if (!isCarouselUp) {
        setIsMoveOnTop(true)
      }
      setIsCarouselUp(!isCarouselUp)
      setIsLoading(false)
    }, [500])
  }

  const liftRail = useSpring({
    top: isCarouselUp ? -height : 0,
    config: {
      frequency: 0.5,
      clamp: 0.1,
    },
    onStart: () => onLiftStart(),
    onRest: () => onLiftRest(),
  })

  const balanceSlideTop = useSpring({
    top: !isMoveOnTop ? 0 : height,
    config: {
      frequency: 0.5,
      clamp: 0.1,
    },
  })
  const balanceSlideBottom = useSpring({
    top: !isMoveOnBottom ? 0 : -height,
    config: {
      frequency: 0.5,
      clamp: 0.1,
    },
  })

  const zoomVideo = useSpring({
    scale: isZoomOn ? 1.3 : 1,
    config: {
      frequency: 6,
      clamp: 2.5,
    },
    onRest: () => isAutoPlay && onButtonClick(1, false),
  })

  const renderMedia = (indexProp, animationProp) => {
    const url = !Number.isNaN(indexProp)
      ? R.pathOr(null, ['imageOrVideo'], carousel[indexProp])
      : null

    return (
      <MainCarouselVideo
        classe1={classes.mediaWrapper}
        indexProp={indexProp}
        topImage={topImage}
        topImageZIndex={topImageZIndex}
        height={height}
        balanceSlideTop={balanceSlideTop}
        bottomImageZIndex={bottomImageZIndex}
        balanceSlideBottom={balanceSlideBottom}
        url={url}
        refTop={refTop}
        refBottom={refBottom}
        animationProp={animationProp}
        classe2={classes.media}
        carousel={carousel}
        carouselLink={carousel[index].buttonLink}
      />
    )
  }

  const renderSlider = () => (
    <div className={classes.slideRightWrapper}>
      <div className={classes.slideRight} ref={ref}>
        <animated.div className={classes.slideImageRail} style={{...liftRail}}>
          {R.length(carousel) > 1 && renderMedia(topImage, zoomVideo)}
          {renderMedia(bottomImage, zoomVideo)}
        </animated.div>
      </div>
      <div className={classes.dotsContainer}>
        {mapIndexed(
          (node, indx) => (
            <a.div
              className={classNames(
                classes.dots,
                index === indx && classes.activeDots,
              )}
            />
          ),
          carousel,
        )}
      </div>
    </div>
  )

  const renderOnMobile = () => (
    <div className={classes.slideWrapper}>
      <div className={classes.slideLeft}>
        <Title variant="h1" type="title" isCentered={!!isMobile}>
          {carousel[index].title}
        </Title>
        <Description
          type="largeDescription"
          className={classes.slideDescription}
        >
          {carousel[index].description.description}
        </Description>
        <div>
          {isMobile && renderSlider()}

          {R.length(carousel) > 1 && (
            <RoundButtonSlider
              isCentered={isMobile}
              hasMarginTop
              decrement={() => !isLoading && onButtonClick(-1, true)}
              increment={() => !isLoading && onButtonClick(1, true)}
              isLoading={isLoading || isMoveOnBottom || isMoveOnTop}
            />
          )}

          {carousel[index].buttonLink && carousel[index].buttonLabel && (
            <div className={classes.mobileCta}>
              <RoundButton
                color="primary"
                href={
                  isExternalLink(carousel[index].buttonLink)
                    ? carousel[index].buttonLink
                    : `../${carousel[index].buttonLink.substring(1)}`
                }
                externalLink={isExternalLink(`${carousel[index].buttonLink}`)}
              >
                {carousel[index].buttonLabel}
              </RoundButton>
            </div>
          )}

          <Social socialMediaLinks={socialMediaLinks} hasMarginTop />
        </div>
      </div>

      {!isMobile && renderSlider()}
    </div>
  )

  const renderAnimatedDescription = () => (
    <p
      key={index}
      data-aos="fade-left"
      data-aos-duration={1000}
      className={classNames(
        classes.description,
        classes.preWrap,
        classes.largeDescription,
      )}
    >
      {carousel[index].description.description}
    </p>
  )

  return (
    <Section hasPaddingTop={false} hasPaddingBottom={false}>
      <MainCarouselDecorations webSiteName={webSiteName} />
      {isMobile ? (
        renderOnMobile()
      ) : (
        <div className={classes.slideWrapper}>
          <div className={classes.slideLeft}>
            <Title
              animation={{animation: 'fade-up', delay: 200, duration: 1000}}
              variant="h1"
              type="title"
              isCentered={!!isMobile}
            >
              {carousel[index].title}
            </Title>
            <span
              data-aos="fade-up"
              data-aos-delay={400}
              data-aos-duration={1000}
            >
              {renderAnimatedDescription()}
            </span>
            <div
              data-aos="fade-up"
              data-aos-delay={600}
              data-aos-duration={1000}
            >
              {carousel[index].buttonLink && carousel[index].buttonLabel && (
                <RoundButton
                  color={ctaColor}
                  href={
                    isExternalLink(carousel[index].buttonLink)
                      ? carousel[index].buttonLink
                      : `../${carousel[index].buttonLink.substring(1)}`
                  }
                  externalLink={isExternalLink(`${carousel[index].buttonLink}`)}
                >
                  {carousel[index].buttonLabel}
                </RoundButton>
              )}

              {isMobile && renderSlider()}

              <div
                data-aos="fade-up"
                data-aos-delay={800}
                data-aos-duration={1000}
              >
                {R.length(carousel) > 1 && (
                  <RoundButtonSlider
                    isCentered={isMobile}
                    hasMarginTop
                    decrement={() => !isLoading && onButtonClick(-1, true)}
                    increment={() => !isLoading && onButtonClick(1, true)}
                    isLoading={isLoading || isMoveOnBottom || isMoveOnTop}
                  />
                )}
              </div>
              <Social socialMediaLinks={socialMediaLinks} hasMarginTop />
            </div>
          </div>

          {!isMobile && renderSlider()}
        </div>
      )}
    </Section>
  )
}

MainCarousel.propTypes = carouselPropTypes.isRequired

export default MainCarousel
