import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({palette, breakpoints, spacing}) => ({
  heading: {
    textAlign: 'center',
    '& p': {marginTop: 10, justifyContent: 'center'},
    paddingBottom: spacing(5.38),
    [breakpoints.down('lg')]: {
      padding: `0 ${spacing(4)} ${spacing(3.63)} ${spacing(4)}`,
    },
  },
  icon: {
    height: spacing(4.13),
    width: 'auto',
    color: palette.primary.main,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: spacing(5.38),
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'start',
    },
  },
  card: {
    width: '100%',
    margin: spacing(1.25),
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.down('sm')]: {
      margin: 'auto',
    },
    '& p': {
      alignItems: 'center',
      textAlign: 'center',
    },
    '&>:first-child': {
      display: 'block',
      lineHeight: 'normal',
      fontSize: '3.125em',
      [breakpoints.down('sm')]: {
        fontSize: '1.625em',
      },
    },
  },
  shortText: {
    minHeight: spacing(6.63),
    maxWidth: spacing(63.25),
    display: 'flex',
    alignItems: 'center',
    margin: `5px auto ${spacing(3.5)} auto`,
    '& p': {
      height: 'fit-content',
      margin: 'auto',
    },
  },
  image: {
    width: '100%',
    position: 'relative',
  },
  cardTitle: {
    marginBottom: 0,
  },
}))

export default useStyles
