import * as R from 'ramda'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import React from 'react'

import {homepagePartnerSectionPropTypes} from 'helpers/propTypes'
import Heading from 'components/UI/Heading'
import Section from 'components/UI/Section'
import Title from 'components/UI/Title'

import useIsMobile from 'hooks/useIsMobile'
import useStyles from './styles'

const Partners = ({ctaTitle, title, hat, data, countryData}) => {
  const CarouselPartners = loadable(
    () => pMinDelay(import('./PartnersCarousel'), 2300),
    {
      fallback: <Section />,
    },
  )
  const RoundButton = loadable(() => import('components/UI/RoundButton'))
  const {countryCode, navLocale} = countryData
  const classes = useStyles()
  const isMobile = useIsMobile()

  return (
    <Section
      hasVerySmallPadding
      hasPaddingTop={!isMobile}
      hasPaddingBottom={!isMobile}
    >
      <Heading isCentered>
        <Title
          variant="h2"
          type="largeTitle"
          hat={hat}
          isCentered
          color="black"
        >
          {title}
        </Title>
      </Heading>
      <CarouselPartners data={data} countryData={countryData} />
      <RoundButton
        arrow
        isCentered
        href={
          R.equals(countryCode, 'worldwide')
            ? `/${countryCode}/playbook#/all-partners`
            : `/${countryCode}/${navLocale}/playbook#/all-partners`
        }
      >
        {ctaTitle}
      </RoundButton>
    </Section>
  )
}

Partners.propTypes = homepagePartnerSectionPropTypes.isRequired

export default Partners
