import * as R from 'ramda'
import {a} from 'react-spring'
import {Link} from 'gatsby'
import Carousel from 'nuka-carousel'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, {useState} from 'react'
import Tooltip from '@mui/material/Tooltip'

import {mediaPropTypes, mediaPropTypesDefault} from 'helpers/propTypes'
import {slugSwitch, stringToSlug} from 'helpers/utils'
import Description from 'components/UI/Description'
import RawText from 'components/UI/RawText'
import Title from 'components/UI/Title'

import useIsMobile from 'hooks/useIsMobile'

import useStyles from './styles'

const MiniPictures = ({
  cards,
  slug,
  tooltipHumanData,
  tooltipInspirationalReality,
  tooltipSimplexFuture,
  isAnimated,
  hasShortPath,
  hasNoTooltip,
  hasNoDescription,
  directLink,
}) => {
  const isMobile = useIsMobile()
  const [index, setIndex] = useState(0)

  const classes = useStyles()
  const mapIndexed = R.addIndex(R.map)

  const newSlug = slugSwitch(slug)

  const getTooltipUrl = tooltipSlug =>
    hasShortPath
      ? `../${newSlug}#${tooltipSlug}`
      : `../about-us/${newSlug}#${tooltipSlug}`

  const getTooltipText = indexCard => {
    switch (indexCard) {
      case 0:
        return tooltipHumanData
      case 1:
        return tooltipInspirationalReality
      case 2:
        return tooltipSimplexFuture
      default:
        return null
    }
  }

  const setLink = val => directLink || getTooltipUrl(stringToSlug(val))

  const renderMiniPicturesRow = () => (
    <>
      <div
        className={`${classes.miniPicturesWrapper} ${classes.miniPicturesWrapperImage}`}
      >
        {mapIndexed(
          (coreValue, indexCard) =>
            hasNoTooltip ? (
              <div className={classes.tooltips}>
                <Link
                  to={directLink || newSlug ? setLink(coreValue.title) : false}
                >
                  <div
                    data-aos={`${isAnimated && 'zoom-in'}`}
                    data-aos-delay={`${(indexCard + 1) * 100}`}
                    data-aos-offset="200"
                    data-aos-duration="1000"
                    key={indexCard}
                    className={classes.cards}
                  >
                    <div className={classes.picturesWrapper}>
                      <img
                        loading="lazy"
                        src={coreValue.image.file.url}
                        alt={coreValue.title}
                      />
                    </div>
                    <div key={indexCard} className={classes.cards}>
                      <Title variant="h3" type="innerTitle">
                        {coreValue.title}
                      </Title>
                      {!hasNoDescription && (
                        <Description hasMarginTop isJustified isCentered>
                          <RawText text={coreValue.description} />
                        </Description>
                      )}
                    </div>
                  </div>
                </Link>
                {/* <Description isJustified isCentered>
                  {' '}
                  {getTooltipText(indexCard)}
                </Description> */}
              </div>
            ) : (
              <Tooltip
                title={getTooltipText(indexCard)}
                placement="bottom"
                className={classes.tooltips}
              >
                <div className={classes.tooltips}>
                  <Link
                    to={
                      directLink || newSlug ? setLink(coreValue.title) : false
                    }
                  >
                    <div
                      data-aos={`${isAnimated && 'zoom-in'}`}
                      data-aos-delay={`${(indexCard + 1) * 100}`}
                      data-aos-offset="200"
                      data-aos-duration="1000"
                      key={indexCard}
                      className={classes.cards}
                    >
                      <div className={classes.picturesWrapper}>
                        <img
                          loading="lazy"
                          src={coreValue.image.file.url}
                          alt={coreValue.title}
                        />
                      </div>
                      <div key={indexCard} className={classes.cards}>
                        <p className={classes.miniPicturesTitle}>
                          {coreValue.title}
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </Tooltip>
            ),
          cards,
        )}
      </div>
    </>
  )

  const renderMiniPicturesCol = () => (
    <div className={classes.miniPicturesWrapper}>
      <Carousel
        slideIndex={index}
        afterSlide={slideIndex => setIndex(slideIndex)}
        wrapAround
        withoutControls
      >
        {R.map(
          card => (
            <Link
              to={`${getTooltipUrl(stringToSlug(card.title))}`}
              style={{display: 'block', height: '100%', width: '100%'}}
            >
              <div className={classes.cards}>
                <div className={classes.picturesWrapper}>
                  <img
                    loading="lazy"
                    src={card.image.file.url}
                    alt={card.title}
                  />
                </div>
              </div>
            </Link>
          ),
          cards,
        )}
      </Carousel>
      <p className={classes.miniPicturesTitle}>{cards[index].title}</p>
      {isMobile && (
        <div className={classes.dotsContainer}>
          {mapIndexed(
            (node, indx) => (
              <a.div
                className={classNames(
                  classes.dots,
                  index === indx && classes.activeDots,
                )}
              />
            ),
            cards,
          )}
        </div>
      )}
    </div>
  )

  return isMobile ? renderMiniPicturesCol() : renderMiniPicturesRow()
}

MiniPictures.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.shape({raw: PropTypes.string}),
      image: mediaPropTypes.isRequired,
      title: PropTypes.string,
      variation: PropTypes.string,
    }),
  ),
  hasNoDescription: PropTypes.bool,
  hasNoTooltip: PropTypes.bool,
  hasShortPath: PropTypes.bool,
  isAnimated: PropTypes.bool,
  slug: PropTypes.string,
  tooltipHumanData: PropTypes.string,
  tooltipInspirationalReality: PropTypes.string,
  tooltipSimplexFuture: PropTypes.string,
}

MiniPictures.defaultProps = {
  cards: [
    {
      image: mediaPropTypesDefault,
      title: '',
      variation: '',
      description: {raw: ''},
    },
  ],
  hasNoDescription: false,
  hasNoTooltip: false,
  hasShortPath: false,
  isAnimated: false,
  slug: '',
  tooltipHumanData: '',
  tooltipInspirationalReality: '',
  tooltipSimplexFuture: '',
}

export default MiniPictures
