import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import React, {useState} from 'react'

import {worldwidePropTypes} from 'helpers/propTypes'

import Description from 'components/UI/Description'
import Section from 'components/UI/Section'
import Title from 'components/UI/Title'

import useStyles from './styles'

const Worldwide = ({
  data,
  countryNameForContactForm,
  locations,
  siteUrl,
  legend,
  ctaColor,
}) => {
  const Decoration = loadable(() => import('components/UI/Decoration'))
  const Decorations = loadable(() => import('components/UI/Decorations'))
  const RoundButton = loadable(() => import('components/UI/RoundButton'))
  const WorldMap = loadable(
    () => pMinDelay(import('components/UI/WorldMap'), 3000),
    {
      fallback: <Section hasGreyBackground />,
    },
  )
  const classes = useStyles()
  const {
    name,
    pageTitle,
    mapMarker,
    kpi,
    phoneLabel,
    faxLabel,
    callToAction,
    visitWebsite,
  } = data
  const [random, setRandom] = useState(0)

  const handleClick = () => {
    if (random + 1 === kpi.length) {
      setRandom(0)
    } else {
      setRandom(random + 1)
    }
  }

  const renderDecoration = () => (
    <Decorations>
      <Decoration webSiteName="keyrus" color="blue" left={0} bottom={-160} />
      <Decoration webSiteName="keyrus" color="grey" right={0} bottom={-60} />
      <Decoration webSiteName="kls" svg="left" left={-80} bottom={-160} />
      <Decoration webSiteName="kls" svg="right" right={0} bottom={-60} />
    </Decorations>
  )

  return (
    <div className="decorationWrapper">
      {/* {renderDecoration()} */}
      <Section hasPaddingSide={false} hasGreyBackground>
        <div className={classes.heading}>
          <Title variant="h2" type="largeTitle" hat={name}>
            {pageTitle}
          </Title>
        </div>
        <WorldMap
          markers={locations}
          phone={phoneLabel}
          fax={faxLabel}
          pins={mapMarker}
          siteUrl={siteUrl}
          visitWebsite={visitWebsite}
          countryNameForContactForm={countryNameForContactForm}
          legend={legend}
        />
        <div className={classes.wrapper}>
          <div className={classes.card}>
            <Title variant="h2" isCentered>
              {kpi[random].number}
            </Title>
            <div className={classes.shortText}>
              <Description>{kpi[random].shortText}</Description>
            </div>
            <RoundButton
              action={() => handleClick()}
              color={ctaColor}
              isAction
              refresh
              isCentered
            >
              {callToAction}
            </RoundButton>
          </div>
        </div>
      </Section>
    </div>
  )
}

Worldwide.propTypes = worldwidePropTypes.isRequired

export default Worldwide
