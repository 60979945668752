import {useTheme} from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const useIsLargeScreen = () => {
  const {breakpoints} = useTheme()

  return useMediaQuery(breakpoints.up('lg'))
}

export default useIsLargeScreen
