import {string} from 'prop-types'
import Decoration from 'components/UI/Decoration'
import Decorations from 'components/UI/Decorations'
import React from 'react'
import useIsLargeScreen from 'hooks/useIsLargeScreen'

const MainCarouselDecorations = () => {
  const isLargeScreen = useIsLargeScreen()

  return (
    <>
      <Decorations>
        {isLargeScreen ? (
          <Decoration webSiteName="kls" svg="dna" left={0} top={-110} />
        ) : (
          <div style={{position: 'relative'}}>
            <Decoration webSiteName="kls" svg="dna" left={-150} top={-115} />
          </div>
        )}
        <Decoration webSiteName="kls" svg="mollecule_2" right={0} top={500} />
        <Decoration
          webSiteName="keyrus"
          color="blue"
          left={0}
          top={54}
          isAnimated
          animation={{direction: 'right', delay: 800, duration: 1200}}
        />
        <Decoration
          webSiteName="keyrus"
          color="orange"
          right={0}
          top={30}
          isAnimated
          animation={{direction: 'left', delay: 1100, duration: 1200}}
        />
        <Decoration
          webSiteName="keyrus"
          color="red"
          right={0}
          bottom={30}
          isAnimated
          animation={{
            direction: 'left',
            delay: 1400,
            offset: -1000,
            duration: 1200,
          }}
        />
        <Decoration
          webSiteName="keyrus-fondation"
          color="blue"
          left={-15}
          top={54}
          isAnimated
          animation={{direction: 'right', delay: 800, duration: 1200}}
        />
        <Decoration
          webSiteName="keyrus-fondation"
          color="blueTop"
          right={-40}
          top={30}
          isAnimated
          animation={{direction: 'left', delay: 1100, duration: 1200}}
        />
        <Decoration
          webSiteName="keyrus-fondation"
          color="blueBottom"
          right={-40}
          bottom={30}
          isAnimated
          animation={{
            direction: 'left',
            delay: 1400,
            offset: -1000,
            duration: 1200,
          }}
        />
        <Decoration
          webSiteName="keyrus-management"
          color="blue"
          left={0}
          top={54}
          isAnimated
          animation={{direction: 'right', delay: 800, duration: 1200}}
        />
        <Decoration
          webSiteName="keyrus-management"
          color="orange"
          right={0}
          top={30}
          isAnimated
          animation={{direction: 'left', delay: 1100, duration: 1200}}
        />
        <Decoration
          webSiteName="keyrus-management"
          color="red"
          right={0}
          bottom={30}
          isAnimated
          animation={{
            direction: 'left',
            delay: 1400,
            offset: -1000,
            duration: 1200,
          }}
        />
      </Decorations>
    </>
  )
}

MainCarouselDecorations.propTypes = {
  webSiteName: string.isRequired,
}

export default MainCarouselDecorations
