import * as R from 'ramda'
import {Link} from 'gatsby'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import React, {useState} from 'react'

import {playbookPropTypes} from 'helpers/propTypes'
import Media from 'components/UI/Media'
import Section from 'components/UI/Section'
import Title from 'components/UI/Title'
import useIsMobile from 'hooks/useIsMobile'

import useStyles from './styles'

const Decoration = loadable(() => import('components/UI/Decoration'))
const Decorations = loadable(() => import('components/UI/Decorations'))

const CardsCarousel = loadable(
  () => pMinDelay(import('./CardsCarousel'), 2300),
  {
    fallback: <Section />,
  },
)

const Playbook = ({playbook, keyPlays}) => {
  const classes = useStyles()

  const renderDecorations = () => (
    <Decorations>
      <Decoration
        webSiteName="keyrus"
        color="orangeTop"
        left={0}
        bottom={-80}
        animation={{direction: 'right', duration: 1000}}
      />
      <Decoration
        webSiteName="keyrus"
        color="blue"
        right={0}
        bottom={-350}
        isRotated
        animation={{direction: 'left', duration: 1000}}
      />
      <Decoration webSiteName="kls" svg="left" left={-80} bottom={-80} />
      <Decoration webSiteName="kls" svg="right" right={0} bottom={-350} />
    </Decorations>
  )

  return (
    <div className={classes.decorationWrapper}>
      {renderDecorations()}
      <CardsCarousel playbook={playbook} keyPlays={keyPlays} />
    </div>
  )
}

Playbook.propTypes = playbookPropTypes.isRequired

export default Playbook
