import loadable from '@loadable/component'
import React from 'react'

import {servicesPropTypes} from 'helpers/propTypes'
import Section from 'components/UI/Section'
import Title from 'components/UI/Title'

const Services = ({services, homepageTitle, name}) => {
  const TopLevelServices = loadable(() => import('components/Services'))

  return (
    <Section hasGreyBackground>
      <Title type="largeTitle" variant="h2" hat={name} isCentered>
        {homepageTitle}
      </Title>
      <TopLevelServices data={services} />
    </Section>
  )
}

Services.propTypes = servicesPropTypes

Services.defaultProps = {
  data: {},
}

export default Services
