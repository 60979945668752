import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(() => ({
  decorationWrapper: {
    width: '100%',
    position: 'relative',
  },
}))

export default useStyles
