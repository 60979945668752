import {bool, string} from 'prop-types'
import {upperFirst} from 'lodash'
import React from 'react'

import useStyles from './styles'

const DateText = ({timeCode, node_locale, titleStyle, rawDate}) => {
  const classes = useStyles()
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  return (
    <span className={titleStyle ? classes.nextDateEvent : classes.date}>
      {rawDate ||
        upperFirst(new Date(timeCode).toLocaleDateString(node_locale, options))}
    </span>
  )
}

DateText.propTypes = {
  node_locale: string,
  rawDate: string,
  timeCode: string,
  titleStyle: bool,
}
DateText.defaultProps = {
  node_locale: '',
  rawDate: '',
  timeCode: '',
  titleStyle: false,
}

export default DateText
