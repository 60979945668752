import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({fontSizes, palette, breakpoints, spacing}) => ({
  wrapper: {
    marginBottom: spacing(6.25),
  },
  subWrapper: {
    position: 'relative',
    marginTop: spacing(6.25),
  },
  bannerWrapper: {
    position: 'relative',
    [breakpoints.up('md')]: {
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1,
        background: '#0d1034',
        opacity: 0.4,
      },
    },
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: 300,
      zIndex: 1,
      background:
        'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
      [breakpoints.down('sm')]: {
        height: 140,
      },
    },
  },
  banner: {
    width: '100%',
  },
  headingWrapper: {
    background: 'white',
    top: 'inherit',
    paddingTop: spacing(5),
    paddingBottom: spacing(5),
    position: 'relative',
    zIndex: 2,
    [breakpoints.down('sm')]: {
      paddingTop: spacing(10),
    },
  },

  parallaxClass: {
    zIndex: 1,
    margin: 0,
    overflow: 'hidden',

    background: palette.background.greyLight,
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    display: 'block',
    [breakpoints.up('md')]: {
      maxHeight: 450,
    },
  },

  textBlock: {
    position: 'relative',
    paddingBottom: 40,
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    margin: `${spacing(2)} 0 0`,
  },
  dotsNavigation: {
    margin: 'auto',
    '& .MuiPaper-root': {background: palette.primary.main},
    [breakpoints.down('sm')]: {
      margin: `0 auto ${spacing(4)} auto`,
    },
  },
  parallaxLazyLoading: {
    backgroundColor: palette.background.greyLight,
    boxShadow: '0px 3px 20px 10px #00000030',
    margin: '40px 0px',
    [breakpoints.up('md')]: {
      minHeight: 450,
    },
  },
}))

export default useStyles
