import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(
  ({fontSizes, palette, layout, breakpoints, spacing}) => ({
    slideWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '100px 3%',
      position: 'relative',
      [breakpoints.down('sm')]: {
        height: 'auto',
        flexDirection: 'column-reverse',
        margin: spacing(6.9, 'auto'),
        maxWidth: layout.mainCarousel.slideWrapper.maxWidth,
      },
    },
    slideLeft: {
      paddingRight: '11%',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      [breakpoints.down('sm')]: {
        marginTop: spacing(2),
        width: '100%',
        paddingRight: 0,
        textAlign: 'center',
      },
      '& > p': {
        [breakpoints.up('md')]: {
          minHeight: 225,
        },
      },
      '& > div': {
        position: 'relative',
        top: 30,
        [breakpoints.down('sm')]: {
          position: 'relative',
          top: 0,
        },
      },
    },
    slideRightWrapper: {
      flex: 1,
      height: 'max-content',
      position: 'relative',
      [breakpoints.down('sm')]: {
        margin: spacing(4, 'auto', 'auto', 'auto'),
      },
    },
    slideRight: {
      width: '100%',
      paddingBottom: '100%',
      position: 'relative',
      background: palette.background.greyLight,
      overflow: 'hidden',
      borderRadius: layout.mainCarousel.image.borderRadius,
    },
    slideImageRail: {
      position: 'absolute',
      width: '100%',
    },
    mediaWrapper: {
      overflow: 'hidden',
      height: '100%',
      width: '100%',
      position: 'relative',
      borderRadius: layout.mainCarousel.image.borderRadius,
      display: 'flex',
      justifyContent: 'center',
    },
    media: {
      height: '100%',
    },
    rightArrow: {
      marginLeft: spacing(4),
      '& > *': {
        transform: 'rotate(180deg)',
      },
    },
    mobileCta: {
      marginTop: spacing(3),
    },

    slideDescription: {
      animation: '$slideInLeft .5s ease-out forwards',
      width: '100%',
    },

    '@keyframes slideInLeft': {
      '0%': {
        marginLeft: 20,
        opacity: 0,
      },
      '1OO%': {
        marginelft: 0,
        opacity: 1,
      },
    },

    dotsNavigation: {
      margin: 'auto',
      '& .MuiPaper-root': {background: palette.primary.main},
      [breakpoints.down('sm')]: {
        margin: `0 auto ${spacing(4)} auto`,
      },
    },

    dotsWrapper: {
      '& > div > a': {
        display: 'flex',
        justifyContent: 'center',
      },
    },

    description: {
      fontFamily: 'CamptonLight',
      color: palette.text.primary,
      marginBottom: spacing(1.25),
    },
    largeDescription: {
      fontSize: fontSizes.largeDescription,
      [breakpoints.down('sm')]: {
        fontSize: fontSizes.description,
      },
    },
    preWrap: {
      whiteSpace: 'pre-wrap',
    },

    dotsContainer: {
      display: 'flex',
      justifyContent: 'center',
      margin: spacing(3),
    },
    dots: {
      borderRadius: '50%',
      width: spacing(1),
      height: spacing(1),
      margin: spacing(0, 1.25, 0, 0),
      backgroundColor: palette.background.gray,
      transitionProperty: 'transform',
      transitionDuration: '0.5s',
    },
    activeDots: {
      backgroundColor: palette.primary.main,
      transform: 'scale(1.3)',
    },
  }),
)

export default useStyles
