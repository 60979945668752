import {useEffect, useState} from 'react'

function useOnScreen(ref, rootMargin = '0px') {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting)
      },
      {
        rootMargin,
      },
    )

    if (ref.current[0].current) {
      return ref.current[0].current && observer.observe(ref.current[0].current)
    }

    return () =>
      ref.current[0].current && observer.unobserve(ref.current[0].current)
  }, [])

  return isIntersecting
}

export default useOnScreen
