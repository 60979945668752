import {Link} from 'gatsby'
import CtaArrowIcon from '@mui/icons-material/CallReceived'
import loadable from '@loadable/component'
import React from 'react'

import {joinUsPropTypes} from 'helpers/propTypes'
import Description from 'components/UI/Description'
import Media from 'components/UI/Media'
import Section from 'components/UI/Section'
import Title from 'components/UI/Title'
import useIsMobile from 'hooks/useIsMobile'

import useStyles from './styles'

const JoinUs = ({data, link, linkIsInternal, logo, hasWideAvatar}) => {
  const Decoration = loadable(() => import('components/UI/Decoration'))
  const Decorations = loadable(() => import('components/UI/Decorations'))
  const RoundButton = loadable(() => import('components/UI/RoundButton'))
  const {
    callToAction,
    workingAtKeyrusImage,
    workingAtKeyrusTitle,
    workingAtKeyrusDescription,
    employeeTestimonyImage,
    employeeTestimonyName,
    employeeTestimonyJobTitle,
    employeeTestimonyDescription,
    employeeTestimonyCallToAction,
    homepageTitle,
    name,
    shortDescription,
  } = data
  const classes = useStyles()
  const isMobile = useIsMobile()

  const offersButton = () => (
    <div data-aos="fade-up" data-aos-duration="1000">
      {linkIsInternal ? (
        <Link to={link} className={classes.link}>
          {employeeTestimonyCallToAction}
          <CtaArrowIcon className={classes.linkArrow} />
        </Link>
      ) : (
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className={classes.link}
        >
          {employeeTestimonyCallToAction}
          <CtaArrowIcon className={classes.linkArrow} />
        </a>
      )}
    </div>
  )

  const renderDecorations = () => (
    <Decorations>
      <Decoration
        webSiteName="keyrus"
        color="grey"
        left={0}
        top={-60}
        isRotated
        animation={{direction: 'right', duration: 1000}}
      />
      <Decoration
        webSiteName="keyrus-management"
        color="grey"
        left={0}
        top={-60}
        isRotated
        animation={{direction: 'right', duration: 1000}}
      />
      <Decoration webSiteName="kls" svg="left" left={-80} top={-60} />
    </Decorations>
  )

  return (
    <>
      <div className="decorationWrapper">
        {renderDecorations()}
        <Section hasGreyBackground>
          <div className={classes.main}>
            <div className={`${classes.submain} ${classes.left}`}>
              <div className={`${classes.topLeft} ${classes.maxWidth}`}>
                <Title
                  variant="h2"
                  type="largeTitle"
                  hat={name}
                  isCentered={!!isMobile}
                >
                  {homepageTitle}
                </Title>
                <Description
                  animation={{animation: 'fade-up', duration: 1000}}
                  isCentered={!!isMobile}
                  type="description"
                >
                  {shortDescription.shortDescription}
                </Description>
                <RoundButton
                  color="primary"
                  arrow
                  href={link}
                  externalLink={!linkIsInternal}
                  isCentered={!!isMobile}
                >
                  {callToAction}
                </RoundButton>
              </div>
              <div className={`${classes.bottomLeft} ${classes.maxWidth}`}>
                {hasWideAvatar && !isMobile && (
                  <div className={classes.wideAvatar}>
                    <Media
                      alt={employeeTestimonyImage.description}
                      data={employeeTestimonyImage.gatsbyImageData}
                    />
                  </div>
                )}
                {!hasWideAvatar && (
                  <>
                    <div
                      data-aos="zoom-in"
                      data-aos-duration={1000}
                      className={classes.avatar}
                    >
                      <Media
                        alt={employeeTestimonyImage.description}
                        data={employeeTestimonyImage.gatsbyImageData}
                      />
                    </div>
                    <div className={classes.bottomLeftText}>
                      <div>
                        <Title
                          type="innerTitle"
                          isCentered={!!isMobile}
                          variant="h3"
                          fontFamily="medium"
                        >
                          {employeeTestimonyName}
                        </Title>
                        <div
                          data-aos="fade-up"
                          data-aos-duration={1000}
                          className={classes.underTitle}
                        >
                          {employeeTestimonyJobTitle}
                        </div>
                        <Description
                          animation={{animation: 'fade-up', duration: 1000}}
                          type="description"
                          isCentered={!!isMobile}
                        >
                          {
                            employeeTestimonyDescription.employeeTestimonyDescription
                          }
                        </Description>
                        {isMobile && offersButton()}
                      </div>
                      {!isMobile && offersButton()}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div
              className={`${classes.submain} ${classes.maxWidth} ${classes.container}`}
            >
              <a target="_blank" rel="noreferrer" href={link}>
                <div className={classes.image}>
                  <Media
                    alt={workingAtKeyrusImage.description}
                    data={workingAtKeyrusImage.gatsbyImageData}
                  />
                </div>
                <div
                  style={{top: hasWideAvatar && !isMobile && '30%'}}
                  className={classes.layer}
                >
                  <img
                    className={classes.logo}
                    src={logo.file.url}
                    alt={logo.title}
                  />
                </div>
              </a>
              <Title
                type="innerTitle"
                isCentered={!!isMobile}
                fontFamily="medium"
                variant="h3"
              >
                {workingAtKeyrusTitle}
              </Title>
              <Description
                animation={{animation: 'fade-up', duration: 1000}}
                isCentered={!!isMobile}
                type="description"
              >
                {workingAtKeyrusDescription.workingAtKeyrusDescription}
              </Description>
            </div>
          </div>
        </Section>
      </div>
    </>
  )
}

JoinUs.propTypes = joinUsPropTypes.isRequired

export default JoinUs
