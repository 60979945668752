import * as R from 'ramda'
import {a} from 'react-spring'
import loadable from '@loadable/component'

import {Link} from 'gatsby'
import Carousel from 'nuka-carousel'
import classNames from 'classnames'
import React, {useState} from 'react'

import {indexChecker} from 'helpers/utils'
import {insightsPropTypes} from 'helpers/propTypes'
import DateText from 'components/UI/Date'
import Media from 'components/UI/Media'
import Section from 'components/UI/Section'
import Title from 'components/UI/Title'
import useIsMobile from 'hooks/useIsMobile'

import useStyles from './styles'

function Insights({data, carousel}) {
  const RoundButton = loadable(() => import('components/UI/RoundButton'))
  const RoundButtonSlider = loadable(() =>
    import('components/UI/RoundButtonSlider'),
  )
  const {callToAction, node_locale, name, homepageTitle} = data
  const classes = useStyles()
  const isMobile = useIsMobile()
  const cards = carousel.edges

  const [indexSlider, setIndexSlider] = useState(0)

  const mapIndexed = R.addIndex(R.map)

  const renderMobile = () => (
    <>
      {R.length(cards) > 0 && (
        <Section>
          <div className={classes.heading}>
            <Title variant="h2" type="largeTitle" isCentered hat={name}>
              {homepageTitle}
            </Title>
          </div>
          <div className={classes.slideWrapper}>
            <Carousel
              slideIndex={indexSlider}
              afterSlide={slideIndex => setIndexSlider(slideIndex)}
              wrapAround
              withoutControls
            >
              {R.map(
                ({node}) =>
                  node && (
                    <Link to={`../insights/${node.slug}`}>
                      <div className={classes.slideLeftMobile}>
                        {node.image && (
                          <Media
                            data={node?.image?.gatsbyImageData}
                            alt={node?.image?.description}
                          />
                        )}
                      </div>
                      <div className={classes.slideRight}>
                        <Title
                          type="subTitle"
                          hat={
                            cards[indexSlider].node.type &&
                            cards[indexSlider].node.type.name
                          }
                        />
                        <p className={classes.date}>
                          <DateText
                            timeCode={cards[indexSlider].node.publicationDate}
                            node_locale={node_locale}
                          />
                        </p>
                        <Title variant="h3" type="innerTitle">
                          {cards[indexSlider].node.title}
                        </Title>
                        <p className={classes.slideDescription}>
                          {R.pathOr(
                            '',
                            ['metaDescription', 'metaDescription'],
                            cards[indexSlider].node,
                          )}
                        </p>
                      </div>
                    </Link>
                  ),
                cards,
              )}
            </Carousel>
          </div>
          <div className={classes.dotsContainer}>
            {mapIndexed(
              (node, indx) => (
                <a.div
                  onClick={() => {
                    setIndexSlider(indx)
                  }}
                  className={classNames(
                    classes.dots,
                    indexSlider === indx && classes.activeDots,
                  )}
                />
              ),
              cards,
            )}
          </div>
          <div className={classes.buttonContainer}>
            <RoundButton
              color="primary"
              href="../insights/"
              arrow
              isCentered
              isNowrap
            >
              {callToAction}
            </RoundButton>
          </div>
        </Section>
      )}
    </>
  )

  const renderDesktop = () => (
    <>
      {R.length(cards) > 0 && (
        <Section>
          <div className={classes.heading}>
            <Title variant="h2" type="largeTitle" isCentered hat={name}>
              {homepageTitle}
            </Title>
          </div>
          <div className={classes.slideWrapper}>
            <div className={classes.slideLeft}>
              <Carousel
                slideIndex={indexSlider}
                afterSlide={slideIndex => setIndexSlider(slideIndex)}
                wrapAround
                withoutControls
              >
                {R.map(
                  ({node}) =>
                    node && (
                      <Link to={`../insights/${node.slug}`}>
                        {node.image && (
                          <Media
                            data={node?.image?.gatsbyImageData}
                            alt={node?.image?.description}
                          />
                        )}
                      </Link>
                    ),
                  cards,
                )}
              </Carousel>
            </div>
            <div className={classes.slideRight}>
              <Title
                type="subTitle"
                hat={
                  cards[indexSlider].node.type &&
                  cards[indexSlider].node.type.name
                }
              />
              <p className={classes.date}>
                <DateText
                  timeCode={cards[indexSlider].node.publicationDate}
                  node_locale={node_locale}
                />
              </p>
              <Title variant="h3" type="innerTitle">
                {cards[indexSlider].node.title}
              </Title>
              <p
                className={classes.slideDescription}
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                {R.pathOr(
                  '',
                  ['metaDescription', 'metaDescription'],
                  cards[indexSlider].node,
                )}
              </p>
            </div>
          </div>
          <RoundButtonSlider
            isCentered
            decrement={() =>
              setIndexSlider(indexChecker(indexSlider, -1, cards))
            }
            increment={() =>
              setIndexSlider(indexChecker(indexSlider, 1, cards))
            }
          />
          <div className={classes.buttonContainer}>
            <RoundButton
              color="primary"
              href="../insights/"
              arrow
              isCentered
              isNowrap
            >
              {callToAction}
            </RoundButton>
          </div>
        </Section>
      )}
    </>
  )

  return <div>{!isMobile ? renderDesktop() : renderMobile()}</div>
}

Insights.propTypes = insightsPropTypes.isRequired

export default Insights
