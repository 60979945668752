import {animated} from 'react-spring'
import {arrayOf, bool, shape, string} from 'prop-types'
import {Link} from 'gatsby'
import React, {useEffect, useState} from 'react'

import {hasProtocol} from 'helpers/utils'
import {mediaPropTypes, mediaPropTypesDefault} from 'helpers/propTypes'
import Media from 'components/UI/Media'

const MainCarouselVideo = ({
  classe1,
  indexProp,
  topImage,
  topImageZIndex,
  height,
  balanceSlideTop,
  bottomImageZIndex,
  balanceSlideBottom,
  url,
  refTop,
  refBottom,
  animationProp,
  classe2,
  carousel,
  carouselLink,
}) => {
  const [assetIsReady, setAssetIsReady] = useState(false)

  const isCurrentImageOrVideo = () => {
    if (topImageZIndex === 1 && indexProp === topImage) {
      return carousel[indexProp]
    }
    if (bottomImageZIndex === 1 && indexProp !== topImage) {
      return carousel[indexProp]
    }
    if (indexProp === 0) {
      return carousel[indexProp]
    }

    return false
  }

  const currentAssetIsImage = url && url.file.contentType.includes('image/')
  const currentAssetIsVideo = url && url.file.contentType.includes('video/')

  const renderInternalLink = link =>
    link.startsWith('/') ? `../${link.replace('/', '')}` : `../${link}`

  const renderCarouselLink = () =>
    hasProtocol(carouselLink)
      ? `${carouselLink}`
      : renderInternalLink(carouselLink)

  useEffect(() => {
    setAssetIsReady(true)
  }, [url])

  return (
    <>
      <Link to={renderCarouselLink()}>
        {assetIsReady && (
          <animated.div
            className={classe1}
            style={
              indexProp === topImage
                ? {zIndex: topImageZIndex, height, ...balanceSlideTop}
                : {
                    zIndex: bottomImageZIndex,
                    height,
                    ...balanceSlideBottom,
                  }
            }
          >
            {isCurrentImageOrVideo() && currentAssetIsVideo && (
              <animated.video
                className={classe2}
                loop
                autoPlay
                muted
                playsInline
                preload="metadata"
                key={url.file.url}
                ref={indexProp === topImage ? refTop : refBottom}
                style={{...animationProp}}
              >
                <source
                  type={carousel[indexProp].imageOrVideo.file.contentType}
                  src={url.file.url}
                />
              </animated.video>
            )}
            {isCurrentImageOrVideo() && currentAssetIsImage && (
              <Media data={url} className={classe2} />
            )}
          </animated.div>
        )}
      </Link>
    </>
  )
}

MainCarouselVideo.propTypes = {
  animationProp: shape({}),
  balanceSlideBottom: shape({}),
  balanceSlideTop: shape({}),
  bottomImageZIndex: bool,
  carousel: arrayOf(
    shape({
      buttonLabel: string,
      buttonLink: string,
      title: string,
      imageOrVideo: mediaPropTypes,
      description: shape({description: string}),
    }),
  ),
  carouselLink: string,
  classe1: string,
  classe2: string,
  height: bool,
  indexProp: bool,
  refBottom: shape({}),
  refTop: shape({}),
  topImage: bool,
  topImageZIndex: bool,
  url: string,
}
MainCarouselVideo.defaultProps = {
  animationProp: {},
  balanceSlideBottom: {},
  balanceSlideTop: {},
  bottomImageZIndex: 0,
  carousel: arrayOf(
    shape({
      buttonLabel: '',
      buttonLink: '',
      title: '',
      imageOrVideo: mediaPropTypesDefault,
      description: shape({description: ''}),
    }),
  ),
  carouselLink: '',
  classe1: '',
  classe2: '',
  height: 0,
  indexProp: 0,
  refBottom: shape({}),
  refTop: shape({}),
  topImage: 0,
  topImageZIndex: 0,
  url: '',
}

export default MainCarouselVideo
