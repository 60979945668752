import {bool, node} from 'prop-types'
import classNames from 'classnames'
import React from 'react'

import useStyles from './styles'

const Heading = ({children, isCentered, hasMargins}) => {
  const classes = useStyles()

  return (
    <div
      className={classNames(classes.heading, {
        [classes.centered]: isCentered,
        [classes.hasMargins]: hasMargins,
      })}
    >
      <div>{children}</div>
    </div>
  )
}

Heading.propTypes = {
  children: node,
  isCentered: bool,
}

Heading.defaultProps = {
  children: null,
  isCentered: false,
}

export default Heading
