import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({palette, spacing}) => ({
  slideNavigation: {
    opacity: 0.8,
    display: 'flex',
    height: 'fit-content',
    gap: spacing(4),
    '& > *': {
      opacity: 1,
      background: palette.background.default,
      height: 75,
      width: 75,
      border: `1px solid ${palette.background.contrastGrey}`,
      '&:hover': {
        background: palette.background.default,
        opacity: 1,
      },
    },
  },
  centered: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  marginTop: {
    marginTop: spacing(4),
  },
  rightArrow: {
    '& > *': {
      transform: 'rotate(180deg)',
    },
  },
  loadingSpinner: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    '& span': {
      marginBottom: spacing(1.5),
    },
  },
  loading: {
    borderColor: palette.primary.main,
  },
}))

export default useStyles
