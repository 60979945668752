import * as R from 'ramda'
import classNames from 'classnames'
import loadable from '@loadable/component'
import React, {useEffect, useRef, useState} from 'react'

import {aboutUsPropTypes} from 'helpers/propTypes'
import {slugSwitch} from 'helpers/utils'
import Description from 'components/UI/Description'
import Media from 'components/UI/Media'
import Section from 'components/UI/Section'
import Title from 'components/UI/Title'
import useIsMobile from 'hooks/useIsMobile'
import useOnScreen from 'hooks/useOnScreen'

import useStyles from './styles'

const AboutUs = ({data}) => {
  const Decoration = loadable(() => import('components/UI/Decoration'))
  const Decorations = loadable(() => import('components/UI/Decorations'))
  const RoundButton = loadable(() => import('components/UI/RoundButton'))
  const {
    homepageTitle,
    name,
    homepageItem3Title,
    homepageItem3ShortDescription,
    homepageItem3Image,
    homepageItem2Title,
    homepageItem2ShortDescription,
    homepageItem2Image,
    homepageItem1Title,
    homepageItem1ShortDescription,
    homepageItem1Image,
    callToAction,
    slug,
  } = data
  const classes = useStyles()
  const mapIndexed = R.addIndex(R.map)
  const newSlug = slugSwitch(slug)
  const isMobile = useIsMobile()

  const refs = useRef([React.createRef(), React.createRef()])

  useOnScreen(refs)

  const onScreen = useOnScreen(refs, '-300px')

  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    if (onScreen) {
      setIntersecting(true)
    }
  })

  const cardsArray = [
    {
      title: homepageItem1Title,
      description: homepageItem1ShortDescription.homepageItem1ShortDescription,
      image: homepageItem1Image,
      animation: 'slide-left',
    },
    {
      title: homepageItem2Title,
      description: homepageItem2ShortDescription.homepageItem2ShortDescription,
      image: homepageItem2Image,
      animation: 'slide-right',
    },
    {
      title: homepageItem3Title,
      description: homepageItem3ShortDescription.homepageItem3ShortDescription,
      image: homepageItem3Image,
      animation: 'slide-left',
    },
  ]

  const renderCard = () =>
    mapIndexed(
      (card, index) => (
        <div
          className={classNames(classes.card, {
            [classes.cardRevert]: index % 2 === 1,
          })}
          key={`${card.title}---${index}`}
        >
          <div className={classes.cardText}>
            <Title variant="h3" type="innerTitle" fontFamily="medium">
              {card.title}
            </Title>
            <Description type="description">{card.description}</Description>
          </div>
          <div
            className={classNames(classes.cardImage, {
              [classes.wipe]: !isIntersecting && !isMobile,
              [classes.t1]: index === 0,
              [classes.t2]: index === 1,
              [classes.t3]: index === 2,
            })}
            ref={refs.current[index]}
          >
            <Media alt={card.image.title} data={card.image.gatsbyImageData} />
          </div>
        </div>
      ),
      cardsArray,
    )

  const renderDecoration = () => (
    <Decorations>
      <Decoration
        webSiteName="keyrus"
        color="redBottom"
        left={0}
        top={60}
        animation={{direction: 'right', offset: 200, duration: 1000}}
      />
      <Decoration
        webSiteName="keyrus"
        color="orange"
        right={0}
        bottom={60}
        animation={{direction: 'left', offset: 200, duration: 1000}}
      />
      <Decoration
        webSiteName="keyrus-management"
        color="redBottom"
        left={0}
        top={60}
        animation={{direction: 'right', offset: 200, duration: 1000}}
      />
      <Decoration
        webSiteName="keyrus-management"
        color="orange"
        right={0}
        bottom={60}
        animation={{direction: 'left', offset: 200, duration: 1000}}
      />
      <Decoration webSiteName="kls" svg="left" left={-80} top={60} />
      <Decoration webSiteName="kls" svg="right" right={0} bottom={60} />
    </Decorations>
  )

  return (
    <div className="decorationWrapper">
      {renderDecoration()}
      <Section hasPaddingTop={false}>
        <div className={classes.heading}>
          <Title variant="h2" isCentered type="largeTitle" hat={name}>
            {homepageTitle}
          </Title>
        </div>
        <div className={classes.cardWrapper}>{renderCard()}</div>
        <div className={classes.cardWrapper}>
          <RoundButton color="primary" arrow href={`../${newSlug}`}>
            {callToAction}
          </RoundButton>
        </div>
      </Section>
    </div>
  )
}

AboutUs.propTypes = aboutUsPropTypes.isRequired

export default AboutUs
